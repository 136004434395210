import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2, OnDestroy, ViewChildren } from '@angular/core';
import { Subscription, ReplaySubject, Subject, Observable, fromEvent, of } from 'rxjs';
import { ApiService } from '../api.service';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
declare var $: any;

@Component({
	selector: 'app-face-login',
	templateUrl: './face-login.component.html',
	styleUrls: ['./face-login.component.css']
})
export class FaceLoginComponent implements OnInit, AfterViewInit, OnDestroy {
	user: any = { email: '', password: '' };
	year: number = new Date().getFullYear();
	loadershow: boolean = false;
	loginErrorshow: boolean = false;
	checkCapsLock: boolean = false;
	loginFormErr: string = "";
	adminAccess:any;
	@ViewChild('bgVideo') bgVideo: ElementRef;
	hideFaceIdModule = true;//environment.production;
	
	@ViewChild("video") public video: ElementRef;
	@ViewChild("canvas") public canvas: ElementRef;
	error: any;
	WIDTH = 200;
	HEIGHT = 150;
	faceErrorFlag:number = 0;
	faceAPILoading = false;
	stream:MediaStream;
	
	data:any = null;
	step:number = 1;
	showQR = false;
	qrToShow:any;
	auth: any = { otp: null };
	qrStep = 1;
	
	constructor(private apiService: ApiService, private router: Router, private renderer: Renderer2, private toastr: ToastrService) {}
	
	ngOnDestroy(): void {
		if(!this.hideFaceIdModule){
			this.clearInterValForImageCapture();
			this.offCamera();
		}
	}
	
	ngAfterViewInit() {
		const media = this.bgVideo.nativeElement;
		this.renderer.setStyle(media, 'width', '100vw');
		this.renderer.setStyle(media, 'height', '100vh');
		this.renderer.setStyle(media, 'object-fit', 'cover');
		this.renderer.setStyle(media, 'position', 'fixed');
		this.renderer.setStyle(media, 'left', '0');
		this.renderer.setStyle(media, 'right', '0');
		this.renderer.setStyle(media, 'top', '0');
		this.renderer.setStyle(media, 'bottom', '0');
		this.renderer.setStyle(media, 'z-index', '-1');
		var isPlaying = media.currentTime > 0 && !media.paused && !media.ended && media.readyState > media.HAVE_CURRENT_DATA;
		if (!isPlaying) {
			media.muted = true;
			try {
				media.play();
			} catch (error) {}
		}
		if(!this.hideFaceIdModule){
			if(localStorage.getItem('token') == null || localStorage.getItem('token') == undefined){
				this.offCamera();
				this.onCamera();
			}
		}
	}
	
	ngOnInit() {
		if(localStorage.getItem('token') != null && localStorage.getItem('token') != undefined){
			this.gotoDashboard();
		}else{
			if(localStorage.getItem('sessionexpire') == "true"){
				this.loginErrorshow = true;
				this.loginFormErr = "Your session has been expired.";
				setTimeout(() => {
					this.loginErrorshow = false;
					localStorage.removeItem('sessionexpire')
				}, 5000);
			}
		}
	}
	
	submitForm(user: any) {
		this.loadershow = true;
		this.apiService.loginAPI(user).subscribe((data:any) => {
			this.loadershow = false;
			this.data = data;
			/* this.data.enable2fa=true;this.data.first_login=false; */
			if(this.data.enable2fa && this.data.first_login){
				this.step = 2;
				this.showQR = true;
				this.generateQR();
			}else if(this.data.enable2fa && !this.data.first_login){
				this.step = 2;
				this.showQR = false;
				this.qrStep = 2;
			}else{
				this.proceedLogin();
			}
		},(error:any)=>{
			this.loadershow = false;
			this.loginErrorshow = true;
			this.loginFormErr = "Email-Id Or Password is incorrect.";
			setTimeout(() => {
				this.loginErrorshow = false;
			}, 5000);
		});
	}
	
	offCamera(){
		try {
			const tracks = this.stream.getTracks();
			tracks.forEach((track:any) => track.stop());
		} catch (e) {}
	}
	
	onCamera() {
		if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			try {
				navigator.mediaDevices.getUserMedia({video: true, audio: false}).then((stream)=>{
					this.stream = stream;
					if (this.stream) {
						this.error = null;
						this.video.nativeElement.srcObject = this.stream;
						try {
							this.video.nativeElement.play();
						} catch (error) {}
						this.clearInterValForImageCapture();
						this.setInterValForImageCapture();
					} else {
						this.error = "You have no output video device";
					}
				});
				
			} catch (e) {
				this.error = e;
			}
		}else{
			this.error = "You have no output video device";
		}
	}
	
	gotoDashboard(){
		if(localStorage.getItem("isCompanyUser")=="true"){
			this.router.navigate(['/comp/company-dashboard']);
		}else{
			if(localStorage.getItem("isSuperAdmin")=="true"){
				this.router.navigate(['/app/admin-dashboard']);
			}else{
				this.router.navigate(["/app/"+this.apiService.getDashboardURL()]);
			}
		}
	}
	
	takeSnapshot(): void {
		this.canvas.nativeElement.getContext("2d").drawImage(this.video.nativeElement, 0, 0, this.WIDTH, this.HEIGHT);
		let DataURL = this.canvas.nativeElement.toDataURL("image/png");
		this.urltoFile(DataURL, 'image.jpeg','image/jpeg').then((file)=>{
			const formData = new FormData();
			formData.append('image', file);
			if(!this.faceAPILoading){
				this.faceAPILoading = true;
				this.apiService.verifyImageOfUser(formData).subscribe((data:any) => {
					this.faceAPILoading = false;
					this.user.email = data.email;
					this.clearInterValForImageCapture();
					this.faceErrorFlag = 1;
				},(error) => {
					this.faceAPILoading = false;
					this.faceErrorFlag = 2;
					if(error.status==401){return}
				});
			}
		});
	}
	
	urltoFile(url:string, filename:string, mimeType:string){
		return (fetch(url).then((res)=>{return res.arrayBuffer();}).then((buf)=>{return new File([buf], filename,{type:mimeType});}));
	}
	
	setInterValForImageCapture(){
		let Id = setInterval(() => {
			this.takeSnapshot();
		}, 5000);
		let existingIds = localStorage.getItem("capture_image_interval_ids");
		if(existingIds==null){
			localStorage.setItem("capture_image_interval_ids",Id.toString());
		}else{
			let existingIdsList = existingIds.split(",");
			existingIdsList.push(Id.toString());
			localStorage.setItem("capture_image_interval_ids",existingIdsList.join(","));
		}
	}
	
	clearInterValForImageCapture(){
		let existingIds = localStorage.getItem("capture_image_interval_ids");
		if(existingIds!=null){
			let existingIdsList = existingIds.split(",");
			for (let i = 0; i < existingIdsList.length; i++) {
				clearInterval(parseInt(existingIdsList[i]))
			}
		}
	}
	
	next(){
		this.qrStep = 2;
	}
	
	generateQR(){
		this.loadershow = true;
		let data:any = {
			Id:this.data.clinic_user_id,
			user:this.data.emailId
		};
		this.apiService.generate_qr(data).subscribe((data:any) => {
			this.loadershow = false;
			this.createImageFromBlob(data);
		},(error:any)=>{
			this.loadershow = false;
			this.loginErrorshow = true;
			this.loginFormErr = "Something went wrong";
			setTimeout(() => {
				this.loginErrorshow = false;
			}, 5000);
		});
	}
	
	createImageFromBlob(image: Blob) {
		let reader = new FileReader();
		reader.addEventListener("load", () => {
			this.qrToShow = reader.result;
		}, false);
		if (image) {
			reader.readAsDataURL(image);
		}
	}
	
	verifyOTP(){
		if(!this.auth.otp){
			this.toastr.warning("Please enter OTP", 'Error',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		this.loadershow = true;
		let data:any = {
			Id:this.data.clinic_user_id,
			otp:this.auth.otp
		};
		this.apiService.verifyGoogleAuthOTP(data).subscribe((data:any) => {
			this.loadershow = false;
			this.proceedLogin();
		},(error:any)=>{
			this.loadershow = false;
			this.loginErrorshow = true;
			this.loginFormErr = "Invalid OTP";
			setTimeout(() => {
				this.loginErrorshow = false;
			}, 5000);
		});
	}
	
	proceedLogin(){
		localStorage.setItem('token', "Bearer "+this.data["token"]);
		localStorage.setItem('isCompanyUser', this.data["isCompanyUser"]);
		if(this.data["isCompanyUser"]){
			localStorage.setItem('company_name', this.data["name"]);
			localStorage.setItem('company_unique_id', this.data["related_unique_id"]);
			localStorage.setItem('related_comps', JSON.stringify(this.data["related_comps"]));
			localStorage.setItem('owners', JSON.stringify(this.data["owners"]));
		}else{
			localStorage.setItem('FirstName', this.data["FirstName"]);
			localStorage.setItem('LastName', this.data["LastName"]);
			localStorage.setItem('emailId', this.data["emailId"]);
			localStorage.setItem('isSuperAdmin', this.data["isSuperAdmin"]);
			localStorage.setItem('isAccountant', this.data["isAccountant"]);
			localStorage.setItem('UserAccess', this.data["UserAccess"]);
			localStorage.setItem('DoctAccess', this.data["DoctAccess"]);
			localStorage.setItem('OwnerId', this.data["OwnerId"]);
			localStorage.setItem('ownerName', this.data["ownerName"]);
			localStorage.setItem('clinic_user_id', this.data["clinic_user_id"]);
			if(this.data["clinic"]){
				localStorage.setItem('clinic', this.data["clinic"]);
				localStorage.setItem("centerId",this.data["clinic"]);
				localStorage.setItem('centerName', this.data["clinic_name"]);
				this.apiService.updateClinicName(this.data["clinic_name"]);
			}
		}
		this.toastr.success("Logged in successfully", 'Success',{
			tapToDismiss: true,
			closeButton: true,
			timeOut:3000
		});
		if(!this.hideFaceIdModule){
			window.location.reload();
		}else{
			this.gotoDashboard();
		}
	}
}
