import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RefreshComponent } from './refresh/refresh.component';
import { LoginComponent } from './login/login.component';
import { RecoverComponent } from './recover/recover.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { FaceLoginComponent } from './face-login/face-login.component';
import { CandidateDetailsComponent } from './appointments/candidate-details/candidate-details.component';

const routes: Routes = [
	{ path: '', redirectTo: '/login', pathMatch: 'full' },
	{ path: 'refresh', component: RefreshComponent },
	{ path: 'login', component: FaceLoginComponent },
	{ path: 'otp-login', component: LoginComponent },
	{ path: 'recover', component: RecoverComponent },
	{ path: 'setnewpassword/:user/:token', component: ResetPasswordComponent },
	{ path: 'candidate-details', component: CandidateDetailsComponent },
	{ path: 'app', data: { breadcrumb: 'TopNavBar'},loadChildren: () => import('./headnavbar-lazy/headnavbar-lazy.module').then(m => m.HeadnavbarLazyModule)},
	{ path: 'comp', data: { breadcrumb: 'TopNavBar'},loadChildren: () => import('./company-login/company-login.module').then(m => m.CompanyLoginModule)},
	{ path: 'hp',loadChildren: () => import('./hp/hp.module').then(m => m.HpModule)},
	{ path: '**', redirectTo: '/login' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
